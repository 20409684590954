define("cheddargorge/router", ["exports", "ember-router-scroll", "cheddargorge/config/environment"], function (_exports, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Router extends _emberRouterScroll.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('education', function () {
      this.route('resources', function () {
        this.route('planning-and-booking');
        this.route('show', {
          path: ':slug'
        });
      });
      this.route('workshop-type', {
        path: ':slug'
      });
      this.route('workshop', {
        path: 'workshop/:slug'
      });
    });
    this.route('rocksport', function () {
      this.route('attraction', {
        path: ':slug'
      });
    });
    this.route('explorer', function () {
      this.route('attraction', {
        path: ':slug'
      });
    });
    this.route('business', {
      path: 'corporate-hire'
    }, function () {
      this.route('activity', {
        path: ':slug'
      });
    });
    this.route('groups', function () {
      this.route('resource', {
        path: ':slug'
      });
    });
    this.route('mumbo-jumbo', function () {
      this.route('show', {
        path: ':slug'
      });
    });
    this.route('whats-on', function () {
      this.route('event', {
        path: ':slug'
      });
    });
    this.route('things-to-do', function () {
      this.route('eating-and-drinking');
      this.route('shopping-and-souvenirs');
    });
    this.route('visitor-info', function () {
      this.route('maps');
      this.route('opening-times');
      this.route('show', {
        path: ':slug'
      });
    });
    this.route('accommodation');
    this.route('products', {
      path: 'tickets'
    }, function () {
      this.route('partners');
      this.route('list', {
        path: ':slug'
      });
    });
    this.route('product', {
      path: 'ticket/:slug'
    });
    this.route('basket', function () {
      this.route('need-to-know');
    });
    this.route('checkout', function () {
      this.route('privacy-policy');
      this.route('terms-of-entry');
    });
    this.route('order', function () {
      this.route('show', {
        path: ':uuid'
      }, function () {
        this.route('complete');
        this.route('payment', function () {
          this.route('tesco');
          this.route('challenge-complete');
        });
      });
    });
    this.route('add-extras');
    this.route('subscriptions', function () {
      this.route('subscription', {
        path: ':slug'
      }, function () {
        this.route('complete', {
          path: 'thank-you'
        });
      });
    });
    this.route('partners', function () {});
    this.route('error404', {
      path: '*'
    });
    this.route('search', function () {
      this.route('query', {
        path: ':query'
      });
    });
    this.route('escape-rooms');
    this.route('vip-pass');
    this.route('upsell', {
      path: 'you-might-also-like'
    });
    this.route('promo', function () {
      this.route('show', {
        path: ':slug'
      });
    });
  });
});